<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-container class="py-0">
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              id="createForm"
            >
              <v-row wrap>
                <v-col cols="12" md="12">
                  <h5><span class="text-danger">* </span>Kindly Note :</h5>
                  <ol class="font-size-h6">
                    <li>
                      Please download sample file here.
                      <a :href="SampleFilePath" target="_blank"
                        >Download sample file</a
                      >
                    </li>
                  </ol>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" md="3">
                  <h6 class="mycard-text1 fsize-3">Upload Excel File</h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    placeholder="Upload Excel File"
                    v-model="UploadedFile"
                    prepend-icon="mdi-file-excel"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="resetUploadFile">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container class="py-0">
            <br />
            <br />
            <v-row wrap>
              <v-col
                align="center"
                cols="12"
                md="12"
                v-if="SuccessMessage !== ''"
              >
                <h5 class="text-success">{{ SuccessMessage }}</h5>
              </v-col>
              <v-col
                align="center"
                cols="12"
                md="12"
                v-if="ErrorMessage !== ''"
              >
                <h5 class="text-danger" v-html="ErrorMessage"></h5>
              </v-col>
            </v-row>
          </v-container>
          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessage: "",
      ErrorMessage: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      SubmitFlag: false,
      valid1: true,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 12,
      JciYearCodeOptions: [],

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      JcomTableRules: [],
      JcomTable: "",
      JcomTableOptions: [],

      ResultFlag: 0,

      SampleFilePath: "",

      UploadedFile: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath =
          server_url + "assets/sample/jcom_table_member_upload_sample.xlsx";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;
      }
    },
    JcomTableOptions: function () {
      console.log("watch JcomTableOptions");
      this.JcomTableOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    resetUploadFile() {
      console.log("resetFile called");
      this.UploadedFile = {};
      this.ErrorMessage = "";
      this.ErrorMessageTxt = {};
      this.SuccessMessage = "";
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_members_upload",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getJcomTableOptions() {
      console.log("getJcomTableOptions called");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "") {
        var selectbox1_source = "JcomTable";
        var selectbox1_destination = "JcomTableOptions";
        var selectbox1_url = "api/jcom/table/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Zone: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (TableType == "") {
          message += "Table Type should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("UploadFile", this.UploadedFile);

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/membership/member_bulk_upload";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var error = "";
        var success = "";
        var records = {};
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            flag = response.data.flag;
            console.log("flag=" + flag);

            success = response.data.success;
            console.log("success=" + success);

            error = response.data.error;
            console.log("error=" + error);

            records = response.data.records;
            console.log({ records });

            thisIns.SuccessMessage = success;
            thisIns.ErrorMessage = error;
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  
  <style lang="scss">
</style>
  